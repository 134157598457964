<template>
  <div class="container">
    <el-image @click="skipPage({name:'home', text:'首页'})" class="logo" src="/static/img/logo.png?v=2" alt="Logo"></el-image>
    <div>
      <ul class="navbar">
        <li @click="skipPage(tab)" v-for="tab in tabs" :class="{actived:false}" :key="tab.name">{{tab.text}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      tabs: [
        // {
        //   name: "home",
        //   text: "首页",
        // },
        {
          name: "about",
          text: "我们",
        },
        {
          name: "textbook",
          text: "课程",
        },
        {
          name: "online_course",
          text: "网课",
        },
        {
          name: "news",
          text: "新闻",
        },
        {
          name: "activity",
          text: "大赛",
        },
        {
          name: "school",
          text: "校区",
        },
        {
          name: "league",
          text: "加盟",
        },
        {
          name: "download",
          text: "软件",
        },
      ],
    };
  },
  methods: {
    skipPage(tab) {
      if (tab.name == this.page) {
        return;
      }
      this.$router.replace({
        path: "/" + tab.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #ff6f1b;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  height: 80px;
  //宽度适配调整的地方
  padding: 0 130px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: 1s;

  // background-color: #000;

  .logo {
    width: 113px;
    height: 38px;

    cursor: pointer;

    transition: 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }
  .navbar {
    display: flex;
    list-style: none;

    li {
      font-size: 16px;

      // font-weight: bold;
      color: rgba($color: #fff, $alpha: 0.8);
      // color: rgba($color: #fff, $alpha: 0.55);
      // margin-right: 50px;
      margin-right: 35px;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        // font-weight: bold;
        // text-decoration: underline;
        color: rgba($color: #fff, $alpha: 1);
      }

      &.actived {
        color: #ffffff;
        cursor: default;
      }
    }
  }
}
</style>

